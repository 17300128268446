.article-body * {
  max-width: 100%;
}

.article-body {
  line-height: 1.8;
}

.article-content h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: var(--font-weight-500);
}

.article-content li,
p {
  margin-block-start: 1.6em;
  margin-block-end: 1.6em;
}

/* 文章内容图片样式 */
.article-body img {
  max-width: 100%; /* 图片最大宽度为容器的 100% */
  height: auto; /* 自动调整高度，保持原始比例 */
  border-radius: 4px;
}

/*video 相关样式 */
.article-body video {
  border-radius: 4px;
}

/* 文章内容文本样式 */
.article-content {
  color: var(--color-text-2);
}

/* 文章标题链接样式 */
.article-title a {
  text-decoration: none;
  color: var(--color-text-1); /* 设置链接文字颜色 */
  font-weight: 500;
}

/* 文章内容链接样式 */
.article-body a {
  transition: color 0.1s linear;
  text-decoration: none;
  color: rgb(var(--primary-6));
  border-bottom: 1px dotted rgb(var(--primary-6)); /* 设置链接文字颜色 */
}

.article-body a:hover {
  text-decoration: none;
  color: rgb(var(--primary-5));
  border-bottom: 1px dotted rgb(var(--primary-5)); /* 设置链接文字颜色 */
}

/* 文章内容引用样式 */
.article-body blockquote {
  border-left: 1px solid var(--color-border-3);
  color: var(--color-text-3);
  margin: 1em 0;
  padding: 0 20px;
}

/*code 相关样式 - 表格 */
.article-content td {
  color: var(--color-text-3);
  font-size: 0.8rem;
}

/*code 相关样式 */
.article-content code {
  font-family: "JetBrains Mono", Consolas, Monaco, "Andale Mono", monospace;
  font-size: 0.8rem;
  background-color: var(--color-neutral-3);
  color: var(--color-text-2);
  padding: 2px;
  border-radius: 2px;
}

/*code 相关样式 */
.article-content figure {
  margin: 16px 0;
}

/*code 相关样式 */
.article-content pre {
  background-color: var(--color-neutral-3);
  line-height: 1.2;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
}

/* 文章列表卡片自定义样式 */
.card-custom-hover-style {
  transition-property: all;
}

.card-custom-hover-style .arco-card-body {
  padding: 10px;
}

.card-custom-hover-style:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 10px rgb(var(--gray-3));
}

.card-custom-hover-style img {
  transition: opacity 0.15s ease-in-out; /* 添加过渡效果 */
}

.card-custom-hover-style:hover img {
  opacity: 0.9; /* 设置透明度为 0.90 */
}

.card-custom-selected-style {
  background-color: var(--color-fill-2);
  border: 1px solid var(--color-border-2);
}

@media screen and (max-width: 700px) {
  .entry-col {
    display: none !important;
  }
}
